.postExampleContainer {
    cursor: pointer;
    border: 1px solid brown;
    border-radius: 8px;
    min-height: 300px;
    padding: 10px;
    position: relative;
}

.postExampleContainerSelected {
    cursor: pointer;
    border: 1px solid brown;
    border-radius: 8px;
    min-height: 300px;
    padding: 10px;
    background-color: rgb(201, 181, 181);
    position: relative;
}

.textExampleContainer {
    position: absolute;
    right: 10px;
    bottom: 10px;
    margin: 0px;
}

.buttonNextWall {
    border: transparent;
    background-color: brown;
    color: #fff;
    font-size: 18px;
    font-weight: 600;
    font-style: italic;
    border-radius: 8px;
    padding: 10px;
    cursor: pointer;
}

.sendButton {
    margin-left: 10px;
}

.buttonNextWall:active {
    background-color: rgb(124, 12, 12);
}

.titleExample {
    height: 25px;
    width: 60%;
    background-color: rgb(218, 218, 218);
}

.textExample {
    height: 15px;
    width: 80%;
    background-color: rgb(218, 218, 218);
    margin-bottom: 10px;
}

.imageExample {
    background-image: url(../../../public/assets/no-image-icon-6.png);
    background-size: cover;
    height: 180px;
    width: 100%;
    background-color: rgb(218, 218, 218);
    margin-bottom: 10px;
}

.videoExample {
    background-image: url(../../../public/assets/no-video.png);
    background-size: cover;
    height: 180px;
    width: 100%;
    background-color: rgb(218, 218, 218);
    margin-bottom: 10px;
}

.mediaContainer {
    padding: 10px;
    height: calc(100vh - 300px);
    text-align: center;
}

.item-mywall {
    cursor: pointer;
    padding: 10px 5px;
    margin: 10px 0px;
    position: relative;
    border-bottom: #eeeeee 1px solid;
}

.item-mywall:hover {
    background-color: #eeeeee;
}

.icon-right {
    position: absolute;
    right: 10px;
    top: 20px;
}