.inputClass:invalid:required {
    border: 2px solid red;
}

.inputClass:valid {
    border: 2px solid #27a008;
}

.isInvalid {
    border: 2px solid red;
}

.isValid {
    border: 2px solid #27a008;
}

