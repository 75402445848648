/* .root {
    margin-top: 70px;
    height: 90%;
    font-family:'Raleway';
} */

.navigation-bar {
    padding-top: 10px;
    width: 100%;
    text-align: 'left';
}

.form-container {
    padding: 0px;
}

.container-width {
    padding-top: 90px;
    padding-left: 100px;
    width: 100%;
    font-family: Arial, Helvetica, sans-serif;
}

.menu-card {
    background-color: #F9F9F9;
    /* height: 35vh; */
    padding: 0px 10px;
    margin-top: 10px;
    border-radius: 10px;
    margin-bottom: 10px;
    width: 310px;
}

.menu-card h3{
    margin: 5px 0px;
}

/* @media screen and (max-width: 560px) {
    .container-width {
        padding-left: 80px;
    }
} */

/* @media screen and (max-width: 400px) {
    .container-width {
        padding-left: 60px;
    }
} */

.buttonsReports {
    position: absolute;
    right: 10;
}

.buttonsReports button {
    color: #fff;
    background-color: grey;
    padding-top: 10;
    padding-bottom: 10;
    padding-left: 20;
    padding-right: 20;
    border-radius: 20;
    border-color: transparent;
}