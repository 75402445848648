.user-name {
    cursor: pointer;
    color: #000
}

.user-name:hover {

}

.user-name:active {
    background-color: rgb(245, 245, 245);
}